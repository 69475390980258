<template>
  <div>
    <!-- Loading -->
    <div
      v-if="isLoading"
      class="d-flex flex-column align-items-center vertical-center"
    >
      <b-spinner variant="primary" />
      <span class="small-gap-top">Memuat Tugas</span>
    </div>

    <!-- Classroom Task -->
    <div v-if="!isLoading">
      <!-- Task Card Header -->
      <b-card class="material-card mb-1">
        <div class="d-flex">
          <div class="mr-1">
            <b-avatar rounded="lg" variant="light-info">
              <i class="card-icon ti ti-clipboard-list " />
            </b-avatar>
          </div>
          <div class="d-flex flex-column">
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column ">
                <span class="text-muted ">Tugas</span>
                <h6 class="font-weight-bolder small-gap-bottom">
                  Tugas Pertemuan : {{ classroomContent.class_content.title }}
                </h6>
              </div>
            </div>

            <b-card-text>
              {{ classroomContent.class_content.description }}. Berikut
              merupakan tugas yang harus dikerjakan oleh siswa pada pertemuan
              ini.
            </b-card-text>
          </div>
        </div>
      </b-card>

      <!-- Scrollable Contetn -->
      <div
        v-if="!isLoading && classroomContent.tasks.length !== 0"
        class="vertical-scrollable"
      >
        <b-row>
          <b-col
            v-for="task in classroomContent.tasks"
            :key="task.id"
            class="mb-0"
            xl="4"
            lg="4"
            md="6"
          >
            <task-card :task="task" />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { BRow, BCol, BCard, BCardText, BAvatar, BSpinner } from 'bootstrap-vue'

import TaskCard from './component/TaskCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BAvatar,
    TaskCard,
    BSpinner,
  },
  data() {
    return {
      // state
      isLoading: true,

      // assets
      baseMaterialBgImage: require('@/assets/images/component/material/material-bg.png'),
      emptyStateImg: require('@/assets/images/illustration/no-connection.svg'),

      // default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
      classroomContent: {},
    }
  },
  beforeRouteEnter(to, from, next) {
    const { breadcrumb } = to.meta

    // set breadcrumb
    to.meta.breadcrumb[breadcrumb.length - 2].params = { id: to.params.id }
    next()
  },
  async mounted() {
    const baseUrl = `${process.env.VUE_APP_BASE_API_URL}`
    const url = `${baseUrl}/students/classrooms/${this.classroomId}/class-contents/${this.contentId}/tasks`
    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'Content-Type': 'multipart/form-data',
      },
    }

    await axios
      .get(url, config)
      .then((response) => {
        const classroomContents = response.data.data

        classroomContents.tasks = classroomContents.tasks.filter(
          (task) => task.is_exam === 0
        )

        classroomContents.tasks.map((task) => {
          if (task.type === 'submission') {
            task.content_type = task.submission_questions[0].type

            if (task.submission_questions[0].submission_students.length > 0) {
              task.is_done = true
            } else {
              task.is_done = false
            }
          }

          if (task.type === 'exam') {
            console.log(task)
            task.content_type = task.exam_questions[0].type

            if (task.exam_questions[0].exam_answer_students.length > 0) {
              task.is_done = true
            } else {
              task.is_done = false
            }
          }
        })

        this.classroomContent = classroomContents

        // set state
        this.isLoading = false
      })
      .catch((error) => {
        this.isLoading = false
        console.error(error)
      })
  },
  methods: {},
}
</script>

<style lang="scss">
.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.drop-file {
  box-shadow: none !important;
  background-color: transparent !important;
  border: 2px dashed #d3d3d3 !important;
  border-radius: 0.428rem !important;
}

.vertical-scrollable {
  position: relative;
  padding-right: 15px;
  height: calc(100vh - 330px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.illustration-small {
  height: 300px;
  object-fit: cover;
}

.min-height-screen {
  min-height: calc(100vh - 190px);
}
</style>
